.dropbtn {
  /* background-color: #4caf50; */
   min-width: 131px;
   min-height: 21px;
  color: rgb(0, 0, 0);
  /* padding: 16px; */
  font-size: 13px;
  border: none;
  cursor: pointer;
}
/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdownContent .opt {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  
}

.currentSelected {
  background: #1226fb5f;

}

/* Change color of dropdown links on hover */
.dropdownContent .opt:hover {
  background-color: #f1f1f1;

  cursor: pointer;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdownContent {
  min-width: 131px;

  display: block;
  cursor: pointer;
  font-size: 13px;

  

}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #8d93ac;
}
