@media (min-width: 681px) {


  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    display: flex;
    justify-content: center;
  }

  .App {}

  .login_form {
    display: flex;
    flex-direction: column;
    width: 320px;
    position: relative;
    border: 1px solid;
    top: 50px;

  }

  .error {
    display: flex;
    flex-direction: column;
    width: 320px;
    border: 1px solid;
  }

  .auth {
    border: 1px solid;
    display: flex;
    justify-content: center;
  }

  .login_form_login {
    margin: 5px;
    text-align: center;
  }

  .login_form_password {
    margin: 5px;
    text-align: center;
  }

  .login_form_enter {
    margin: 5px;
    margin-left: 20px;
    margin-right: 20px;
    width: 200px;
  }

  .tasks {
    display: flex;
    flex-direction: column;
  }

  .all_task {
    display: flex;
    border: 1px solid;
    padding: 10px;
    gap: 20px;
  }

  .in_queue {
    border-radius: 25px;
    border: 1px solid;
  }

  .in_progress {
    border: 1px solid;
    border-radius: 25px;

  }

  .done {
    border: 1px solid;
    border-radius: 25px;
  }

  .one_task_header {
    display: flex;
    justify-content: center;
    border-bottom: solid;
  }

  .one_task {
    display: flex;
    flex-direction: column;
    border: 1px solid;
    padding: 5px;
    margin: 10px;
  }

  .one_task_header {
    display: flex;
  }

  .modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal_body {
    display: flex;
    width: 320px;
    flex-direction: column;
    background-color: white;
    gap: 10px;
    border: solid 2px black;
  }

  .modal_input {
    display: flex;
  }

  .modal_content_text {
    display: flex;
    text-align: initial;
  }

  .modal_title {
    display: flex;
    justify-content: center;
    border-bottom: solid;
  }

  .modal_input {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .modal_author_name {
    display: flex;
  }

  .modal_content {
    display: flex;
  }

  .modul_select {
    display: flex;
    width: 100px;
    height: 21px;
    font-size: 1px;
  }

  .modul_footer {
    display: flex;
  }

  .modul_btn {
    width: 100px;
  }




  .one_task_img {
    height: 40px;
    width: 40px;
  }

  .tasks_header {
    border: solid 1px black;
    display: flex;
    align-items: center;
    width: 165px;
    margin: 10px;
  }

}

@media (max-width: 680px) {


  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    display: flex;
    justify-content: center;
  }

  .App {}

  .login_form {
    display: flex;
    flex-direction: column;
    width: 320px;
    position: relative;
    /* justify-content: center; */
    /* align-items: center; */
    border: 1px solid;
    top: 50px;

  }

  .error {
    display: flex;
    flex-direction: column;
    width: 320px;
    border: 1px solid;
  }

  .auth {
    border: 1px solid;
    display: flex;
    justify-content: center;
  }

  .login_form_login {
    margin: 5px;
    text-align: center;
  }

  .login_form_password {
    margin: 5px;
    text-align: center;
  }

  .login_form_enter {
    margin: 5px;
    margin-left: 20px;
    margin-right: 20px;
    width: 200px;
  }

  .tasks {
    display: flex;
    flex-direction: column;
  }

  .all_task {
    display: flex;
    flex-direction: column;
    border: 1px solid;
    padding: 10px;
    gap: 20px;
  }

  .in_queue {
    border-radius: 25px;
    border: 1px solid;
    width: 205px;
  }

  .in_progress {
    border: 1px solid;
    border-radius: 25px;
    width: 205px;

  }

  .done {
    border: 1px solid;
    border-radius: 25px;
    width: 205px;
  }

  .one_task_header {
    display: flex;
    justify-content: center;
    border-bottom: solid;
  }

  .one_task {
    display: flex;
    flex-direction: column;
    border: 1px solid;
    /* margin-top: 20px; */
    /* margin-left: 20px; */
    /* margin-right: 20px; */
    /* border-radius: 15px; */
    padding: 5px;
    margin: 10px;
  }

  .one_task_header {
    display: flex;
  }

  .modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal_body {
    display: flex;
    width: 320px;
    flex-direction: column;
    background-color: white;
    gap: 10px;
    border: solid 2px black;

  }

  .modal_input {
    display: flex;
  }

  .modal_content_text {
    display: flex;
    text-align: initial;
  }

  .modal_title {
    display: flex;
    justify-content: center;
    border-bottom: solid;
  }

  .modal_input {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .modal_author_name {
    display: flex;
  }

  .modal_content {
    display: flex;
  }

  .modul_select {
    display: flex;
    width: 100px;
    height: 21px;
    font-size: 1px;
  }

  .modul_footer {
    display: flex;
  }

  .modul_btn {
    width: 100px;
  }

  .one_task_img {
    height: 40px;
    width: 40px;
  }

  .tasks_header {
    border: solid 1px black;
    display: flex;
    align-items: center;
    width: 165px;
    margin: 10px;
  }

}